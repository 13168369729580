function Icon(props) {
  const { name, size, scale, className } = props;
  let renderedSize = 12;

  if (size === "lg") {
    renderedSize = 20;
  } else if (size === "xl") {
    renderedSize = 24;
  } else if (size === "2xl") {
    renderedSize = 32;
  } else if (size === "3xl") {
    renderedSize = 64;
  }

  if (scale > 1) {
    renderedSize *= scale;
  }

  if (name === "hamburger") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23"
        height="17"
        viewBox="0 0 23 17"
        fill="none"
      >
        <path
          d="M1 8.38464H22"
          stroke="#1D2639"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 1H22"
          stroke="#1D2639"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 15.7693H22"
          stroke="#1D2639"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "spinner") {
    return (
      <svg
        id="spinner"
        width={renderedSize}
        height={renderedSize}
        viewBox="0 0 16 16"
        fill="none"
        strokeLinecap="round"
        stroke="currentColor"
        strokeWidth="1px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="spinnerLine"
          cx="8"
          cy="8"
          r="7"
          stroke-dasharray="1 0.8"
          stroke-dashoffset="1"
          pathLength="1"
        ></circle>
        <circle
          cx="8"
          cy="8"
          r="7"
          stroke-opacity="0.1"
          stroke-dasharray="0.8 1"
          pathLength="1"
        ></circle>
        <circle
          cx="8"
          cy="8"
          r="7"
          stroke-opacity="0.3"
          stroke-dasharray="0.2 1"
          pathLength="1"
          transform="rotate(-72 8 8)"
        ></circle>
      </svg>
    );
  } else if (name === "close") {
    return (
      <svg
        width="20"
        height="20"
        fill="currentColor"
        focusable="false"
        viewBox="0 0 24 24"
      >
        <path d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z"></path>
      </svg>
    );
  } else if (name === "close-nav-btn") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path d="M3 2L10 10L3 18" stroke="#0072CE" strokeLinecap="round" />
        <path d="M17 2L10 10L17 18" stroke="#0072CE" strokeLinecap="round" />
      </svg>
    );
  } else if (name === "hedging-etfs") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="84"
        height="32"
        viewBox="0 0 84 32"
        fill="none"
      >
        <path
          d="M76.1389 0C77.4529 0 78.6409 0.324 79.7029 0.972001C80.7649 1.602 81.5569 2.466 82.0969 3.546L79.7569 4.428C79.0369 3.132 77.7229 2.322 76.1389 2.322C74.9509 2.322 73.9609 2.736 73.1689 3.546C72.3769 4.356 71.9809 5.364 71.9809 6.57C71.9809 7.776 72.3589 8.784 73.1329 9.594C73.9069 10.404 74.8429 10.8 75.9589 10.8C76.8049 10.8 77.5969 10.548 78.2989 10.062C79.0009 9.576 79.4869 8.928 79.7569 8.118H75.7789V5.922H82.4929C82.5109 6.192 82.5289 6.39 82.5289 6.552C82.5289 8.406 81.8989 9.972 80.6569 11.25C79.4149 12.51 77.8489 13.14 75.9949 13.14C74.1409 13.14 72.5749 12.51 71.3329 11.268C70.0909 10.008 69.4609 8.442 69.4609 6.57C69.4609 4.698 70.0909 3.132 71.3689 1.89C72.6469 0.63 74.2309 0 76.1389 0Z"
          fill="#56345B"
        />
        <path
          d="M58.9236 12.8695H56.6016V0.269531H58.7436L65.3856 8.72953V0.269531H67.7076V12.8695H65.5656L58.9236 4.42753V12.8695Z"
          fill="#56345B"
        />
        <path
          d="M54.5136 12.8695H52.1016V0.269531H54.5136V12.8695Z"
          fill="#56345B"
        />
        <path
          d="M43.971 0C45.285 0 46.473 0.324 47.535 0.972001C48.597 1.602 49.389 2.466 49.929 3.546L47.589 4.428C46.869 3.132 45.555 2.322 43.971 2.322C42.783 2.322 41.793 2.736 41.001 3.546C40.209 4.356 39.813 5.364 39.813 6.57C39.813 7.776 40.191 8.784 40.965 9.594C41.739 10.404 42.675 10.8 43.791 10.8C44.637 10.8 45.429 10.548 46.131 10.062C46.833 9.576 47.319 8.928 47.589 8.118H43.611V5.922H50.325C50.343 6.192 50.361 6.39 50.361 6.552C50.361 8.406 49.731 9.972 48.489 11.25C47.247 12.51 45.681 13.14 43.827 13.14C41.973 13.14 40.407 12.51 39.165 11.268C37.923 10.008 37.293 8.442 37.293 6.57C37.293 4.698 37.923 3.132 39.201 1.89C40.479 0.63 42.063 0 43.971 0Z"
          fill="#56345B"
        />
        <path
          d="M29.3171 12.8695H24.2051V0.269531H29.3171C31.1891 0.269531 32.7551 0.881532 33.9971 2.08753C35.2571 3.29353 35.8871 4.78753 35.8871 6.56953C35.8871 8.35153 35.2571 9.84553 33.9971 11.0515C32.7551 12.2575 31.1891 12.8695 29.3171 12.8695ZM29.3171 2.50153H26.6171V10.6375H29.3171C30.4691 10.6375 31.4411 10.2415 32.2151 9.46753C32.9891 8.67553 33.3851 7.70353 33.3851 6.56953C33.3851 5.41753 32.9891 4.44553 32.2151 3.67153C31.4411 2.89753 30.4691 2.50153 29.3171 2.50153Z"
          fill="#56345B"
        />
        <path
          d="M13.166 0.269531H22.454V2.50153H15.578V5.38153H21.464V7.61353H15.578V10.6375H22.544V12.8695H13.166V0.269531Z"
          fill="#56345B"
        />
        <path
          d="M2.48231 12.8695H0.0703125V0.269531H2.48231V5.38153H8.65631V0.269531H11.0863V12.8695H8.65631V7.61353H2.48231V12.8695Z"
          fill="#56345B"
        />
        <path
          d="M82.9403 23.7602L80.8343 24.1022C80.6363 23.4902 80.0963 23.1842 79.1963 23.1842C78.4583 23.1842 77.8823 23.4722 77.8823 23.9942C77.8823 24.3002 78.1523 24.5522 78.7103 24.7502L80.6363 25.3982C82.3643 25.9922 83.0843 26.8562 83.0843 28.2062C83.0843 30.1502 81.5363 31.1402 79.3763 31.1402C77.1263 31.1402 75.7583 30.0962 75.4883 28.3862L77.5943 28.0802C77.7563 28.7462 78.4043 29.1962 79.4303 29.1962C80.2943 29.1962 80.9423 28.8542 80.9423 28.2782C80.9423 27.9182 80.6003 27.6122 79.8983 27.3782L77.9723 26.7662C76.3883 26.2442 75.7403 25.4702 75.7403 24.1562C75.7403 22.1942 77.2163 21.2402 79.3583 21.2402C81.3923 21.2402 82.7063 22.1942 82.9403 23.7602Z"
          fill="#56345B"
        />
        <path
          d="M65.1895 18.2705H74.4775V20.5025H67.6015V23.3825H73.4875V25.6145H67.6015V30.8705H65.1895V18.2705Z"
          fill="#56345B"
        />
        <path
          d="M56.6975 20.5025H52.5215V18.2705H63.3035V20.5025H59.1275V30.8705H56.6975V20.5025Z"
          fill="#56345B"
        />
        <path
          d="M41.6953 18.2705H50.9833V20.5025H44.1073V23.3825H49.9933V25.6145H44.1073V28.6385H51.0733V30.8705H41.6953V18.2705Z"
          fill="#56345B"
        />
        <path
          d="M26.6672 26.8701H24.9922V18.1201H26.6672V21.6701H30.9547V18.1201H32.6422V26.8701H30.9547V23.2201H26.6672V26.8701Z"
          fill="#56345B"
        />
        <path
          d="M19.1734 19.6701H16.2734V18.1201H23.7609V19.6701H20.8609V26.8701H19.1734V19.6701Z"
          fill="#56345B"
        />
        <path
          d="M15.048 26.8701H13.373V18.1201H15.048V26.8701Z"
          fill="#56345B"
        />
        <path
          d="M4.3375 26.8701H2.9625L0 18.1201H1.725L3.6375 24.1826L5.4875 18.1201H6.8625L8.725 24.1826L10.625 18.1201H12.3375L9.375 26.8701H8.0125L6.175 21.0201L4.3375 26.8701Z"
          fill="#56345B"
        />
      </svg>
    );
  } else if (name === "hedging-inverse") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="82"
        height="36"
        viewBox="0 0 82 36"
        fill="none"
      >
        <path
          d="M2.37055 11.6729H0V0.244887H2.37055V4.88141H8.43843V0.244887H10.8267V11.6729H8.43843V6.90581H2.37055V11.6729Z"
          fill="#56345B"
        />
        <path
          d="M12.8706 0.244887H21.999V2.26928H15.2412V4.88141H21.026V6.90581H15.2412V9.64854H22.0875V11.6729H12.8706V0.244887Z"
          fill="#56345B"
        />
        <path
          d="M28.7441 11.6729H23.72V0.244887H28.7441C30.5839 0.244887 32.123 0.799964 33.3437 1.89379C34.582 2.98762 35.2012 4.34266 35.2012 5.95891C35.2012 7.57517 34.582 8.93021 33.3437 10.024C32.123 11.1179 30.5839 11.6729 28.7441 11.6729ZM28.7441 2.26928H26.0905V9.64854H28.7441C29.8763 9.64854 30.8316 9.28938 31.5923 8.58737C32.353 7.86903 32.7422 6.98744 32.7422 5.95891C32.7422 4.91406 32.353 4.03247 31.5923 3.33046C30.8316 2.62845 29.8763 2.26928 28.7441 2.26928Z"
          fill="#56345B"
        />
        <path
          d="M43.146 0C44.4374 0 45.6049 0.293864 46.6487 0.881593C47.6924 1.453 48.4708 2.23663 49.0015 3.21618L46.7018 4.01614C45.9941 2.84069 44.7027 2.10603 43.146 2.10603C41.9784 2.10603 41.0054 2.48152 40.227 3.21618C39.4486 3.95084 39.0594 4.86509 39.0594 5.95891C39.0594 7.05274 39.4309 7.96699 40.1916 8.70165C40.9523 9.43631 41.8722 9.79548 42.969 9.79548C43.8005 9.79548 44.5789 9.56691 45.2688 9.12612C45.9588 8.68532 46.4364 8.09759 46.7018 7.36293H42.7921V5.37119H49.3907C49.4084 5.61607 49.4261 5.79566 49.4261 5.94259C49.4261 7.62415 48.807 9.04449 47.5863 10.2036C46.3656 11.3464 44.8266 11.9178 43.0044 11.9178C41.1823 11.9178 39.6432 11.3464 38.4225 10.2199C37.2019 9.07714 36.5827 7.6568 36.5827 5.95891C36.5827 4.26103 37.2019 2.84069 38.4579 1.71421C39.714 0.571403 41.2707 0 43.146 0Z"
          fill="#56345B"
        />
        <path
          d="M53.5075 11.6729H51.137V0.244887H53.5075V11.6729Z"
          fill="#56345B"
        />
        <path
          d="M57.8418 11.6729H55.5597V0.244887H57.6648L64.1927 7.91801V0.244887H66.4748V11.6729H64.3696L57.8418 4.01614V11.6729Z"
          fill="#56345B"
        />
        <path
          d="M74.7611 0C76.0525 0 77.2201 0.293864 78.2638 0.881593C79.3075 1.453 80.0859 2.23663 80.6167 3.21618L78.3169 4.01614C77.6092 2.84069 76.3178 2.10603 74.7611 2.10603C73.5935 2.10603 72.6205 2.48152 71.8421 3.21618C71.0637 3.95084 70.6745 4.86509 70.6745 5.95891C70.6745 7.05274 71.046 7.96699 71.8067 8.70165C72.5674 9.43631 73.4873 9.79548 74.5842 9.79548C75.4156 9.79548 76.194 9.56691 76.8839 9.12612C77.5739 8.68532 78.0515 8.09759 78.3169 7.36293H74.4072V5.37119H81.0059C81.0235 5.61607 81.0412 5.79566 81.0412 5.94259C81.0412 7.62415 80.4221 9.04449 79.2014 10.2036C77.9808 11.3464 76.4417 11.9178 74.6195 11.9178C72.7974 11.9178 71.2583 11.3464 70.0377 10.2199C68.817 9.07714 68.1978 7.6568 68.1978 5.95891C68.1978 4.26103 68.817 2.84069 70.073 1.71421C71.3291 0.571403 72.8858 0 74.7611 0Z"
          fill="#56345B"
        />
        <path
          d="M3.11908 19.863H2.13032L0 13.5068H1.24044L2.61571 17.9108L3.94604 13.5068H4.9348L6.27411 17.9108L7.6404 13.5068H8.87185L6.74153 19.863H5.76176L4.44042 15.6135L3.11908 19.863Z"
          fill="#415580"
        />
        <path
          d="M10.6775 19.863H9.47304V13.5068H10.6775V19.863Z"
          fill="#415580"
        />
        <path
          d="M13.5 14.6328H11.4146V13.5068H16.7988V14.6328H14.7135V19.863H13.5V14.6328Z"
          fill="#415580"
        />
        <path
          d="M18.7445 19.863H17.54V13.5068H18.7445V16.0856H21.8277V13.5068H23.0411V19.863H21.8277V17.2116H18.7445V19.863Z"
          fill="#415580"
        />
        <path
          d="M27.2347 23.1642H25.4248V13.7094H27.2347V23.1642Z"
          fill="#56345B"
        />
        <path
          d="M30.5439 23.1642H28.8015V13.7094H30.4088L35.3929 20.0577V13.7094H37.1353V23.1642H35.5279L30.5439 16.8295V23.1642Z"
          fill="#56345B"
        />
        <path
          d="M43.5024 23.1642H42.0301L38.0456 13.7094H39.9501L42.773 20.5709L45.5824 13.7094H47.4869L43.5024 23.1642Z"
          fill="#56345B"
        />
        <path
          d="M48.3891 13.7094H55.3587V15.3843H50.199V17.5454H54.6158V19.2202H50.199V21.4894H55.4262V23.1642H48.3891V13.7094Z"
          fill="#56345B"
        />
        <path
          d="M56.6726 13.7094H61.0489C61.9808 13.7094 62.7507 14.0066 63.372 14.5874C64.0069 15.1682 64.3175 15.8976 64.3175 16.762C64.3175 17.9911 63.6152 19.0311 62.5211 19.5174L64.6822 23.1642H62.6697L60.6842 19.8281H58.4826V23.1642H56.6726V13.7094ZM61.0489 15.3843H58.4826V18.1532H61.0489C61.8458 18.1532 62.4266 17.5589 62.4266 16.762C62.4266 15.9651 61.8458 15.3843 61.0489 15.3843Z"
          fill="#56345B"
        />
        <path
          d="M72.0748 15.6274L70.2649 16.1542C70.1163 15.6544 69.495 15.2357 68.7386 15.2357C67.9417 15.2357 67.3069 15.6814 67.3069 16.2352C67.3069 16.5864 67.5905 16.8836 68.1713 17.1267C68.4279 17.2347 68.9412 17.3968 69.7246 17.6264C70.3054 17.7885 70.5485 17.8425 71.1293 18.1532C71.413 18.3018 71.6426 18.4774 71.8047 18.653C72.1288 18.9906 72.4395 19.6254 72.4395 20.3683C72.4395 21.2733 72.1153 22.0027 71.4535 22.5564C70.8052 23.0967 69.9677 23.3669 68.9277 23.3669C67.9957 23.3669 67.1853 23.1507 66.5235 22.7185C65.8616 22.2728 65.4294 21.7325 65.2268 21.0707L67.0367 20.5304C67.1853 21.1247 67.9417 21.6245 68.9277 21.6245C69.8867 21.6245 70.6296 21.1787 70.6296 20.5304C70.6296 20.2603 70.454 20.0171 70.2919 19.8686C70.2108 19.801 70.0758 19.72 69.9002 19.639C69.5625 19.4769 69.5085 19.4769 69.1438 19.3553C68.0092 19.0176 67.2528 18.7475 66.8611 18.5449C65.9562 18.0587 65.4969 17.3293 65.4969 16.3703C65.4969 15.5194 65.7941 14.8305 66.3884 14.3037C66.9962 13.777 67.7661 13.5068 68.7116 13.5068C69.5355 13.5068 70.2649 13.7094 70.8727 14.1146C71.4805 14.5063 71.8857 15.0196 72.0748 15.6274Z"
          fill="#56345B"
        />
        <path
          d="M73.7541 13.7094H80.7236V15.3843H75.564V17.5454H79.9807V19.2202H75.564V21.4894H80.7912V23.1642H73.7541V13.7094Z"
          fill="#56345B"
        />
        <path
          d="M25.4248 26.3428H32.3944V28.0176H27.2347V30.1787H31.6515V31.8536H27.2347V34.1227H32.4619V35.7976H25.4248V26.3428Z"
          fill="#56345B"
        />
        <path
          d="M36.3827 28.0176H33.2491V26.3428H41.3397V28.0176H38.2061V35.7976H36.3827V28.0176Z"
          fill="#56345B"
        />
        <path
          d="M42.4535 26.3428H49.423V28.0176H44.2634V30.1787H48.6802V31.8536H44.2634V35.7976H42.4535V26.3428Z"
          fill="#56345B"
        />
        <path
          d="M55.4739 30.4624L53.8936 30.719C53.745 30.2598 53.3398 30.0301 52.6645 30.0301C52.1107 30.0301 51.6785 30.2463 51.6785 30.638C51.6785 30.8676 51.8811 31.0567 52.2998 31.2052L53.745 31.6915C55.0417 32.1372 55.582 32.7856 55.582 33.7986C55.582 35.2573 54.4204 36.0002 52.7995 36.0002C51.1112 36.0002 50.0847 35.2168 49.8821 33.9336L51.4624 33.704C51.5839 34.2038 52.0702 34.5414 52.8401 34.5414C53.4884 34.5414 53.9746 34.2848 53.9746 33.8526C53.9746 33.5825 53.718 33.3528 53.1912 33.1773L51.746 32.718C50.5574 32.3263 50.0712 31.7455 50.0712 30.7595C50.0712 29.2873 51.1787 28.5714 52.786 28.5714C54.3123 28.5714 55.2983 29.2873 55.4739 30.4624Z"
          fill="#56345B"
        />
      </svg>
    );
  } else if (name === "hedging-white") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="20"
        viewBox="0 0 15 20"
        fill="none"
      >
        <path
          d="M5.43938 5.50495L5.3682 5.4347C5.26226 5.54205 5.2309 5.70278 5.28872 5.84232C5.34644 5.98196 5.48229 6.07309 5.63305 6.07309L7.46704 6.07309L7.46704 13.9242C7.46704 14.1306 7.63339 14.2989 7.84001 14.2989L9.14082 14.2989L6.048 17.4437L2.92249 14.2989L4.19614 14.2989C4.40276 14.2989 4.56911 14.1306 4.56911 13.9242L4.56911 2.94506C4.56911 2.73867 4.40276 2.57041 4.19614 2.57041C3.98952 2.57041 3.82317 2.73867 3.82317 2.94506L3.82317 13.5496L2.02303 13.5496C1.87179 13.5496 1.73597 13.6412 1.67834 13.7811M5.43938 5.50495L1.7708 13.8192M5.43938 5.50495L5.36821 5.4347L9.27641 1.47495C9.34638 1.404 9.44204 1.36401 9.54143 1.36401C9.64034 1.36401 9.73549 1.40342 9.80548 1.47385L13.741 5.43363L13.7411 5.43369C13.8474 5.54081 13.8793 5.70167 13.8217 5.84158C13.764 5.98148 13.6282 6.07309 13.477 6.07309L11.7071 6.07309L11.7071 16.6982C11.7071 16.9046 11.5408 17.0729 11.3342 17.0729C11.1275 17.0729 10.9612 16.9046 10.9612 16.6982L10.9612 5.69844C10.9612 5.49205 11.1275 5.32379 11.3342 5.32379L12.5776 5.32379L9.54236 2.26981L6.5282 5.32379L7.84015 5.32379C8.04677 5.32379 8.21312 5.49205 8.21312 5.69844L8.21312 13.5496L10.0337 13.5496C10.1846 13.5496 10.3202 13.6408 10.378 13.7802C10.4358 13.9195 10.4049 14.08 10.2991 14.1876L10.2278 14.1175M5.43938 5.50495L5.78542 18.24L5.85634 18.1695M1.67834 13.7811L1.7708 13.8192M1.67834 13.7811C1.67834 13.7811 1.67835 13.7811 1.67836 13.7811L1.7708 13.8192M1.67834 13.7811C1.6207 13.9209 1.65265 14.0819 1.75891 14.189L1.75897 14.1891L5.78541 18.24L5.85634 18.1695M1.7708 13.8192C1.72849 13.9218 1.75197 14.04 1.8299 14.1186L5.85634 18.1695M5.85634 18.1695C5.9078 18.2213 5.97754 18.2501 6.05015 18.25C6.12276 18.2499 6.19237 18.2205 6.24341 18.1686L10.2278 14.1175M10.2278 14.1175L10.2991 14.1876L6.31471 18.2387L10.2278 14.1175Z"
          fill="white"
          stroke="#771B85"
          strokeWidth="0.2"
        />
      </svg>
    );
  } else if (name === "hedging-purple") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19"
        height="26"
        viewBox="0 0 19 26"
        fill="none"
      >
        <g clip-path="url(#clip0_3528_3368)">
          <path
            d="M18.1293 6.7684L18.1292 6.76833L12.3084 0.912054C12.3084 0.912053 12.3084 0.912052 12.3084 0.912051C12.2152 0.818263 12.0888 0.765963 11.9571 0.766212C11.8253 0.766461 11.6991 0.819826 11.6066 0.913822M18.1293 6.7684L11.6779 0.983937M18.1293 6.7684C18.2703 6.9105 18.3127 7.12419 18.2362 7.3097M18.1293 6.7684L7.2615 25.0858M11.6066 0.913822L11.6779 0.983937M11.6066 0.913822L11.6066 0.913815L11.6779 0.983937M11.6066 0.913822L5.84658 6.77029L5.91788 6.84041L11.6779 0.983937M7.2615 25.0858L7.19033 25.0155L7.26153 25.0858M7.2615 25.0858C7.26151 25.0858 7.26152 25.0858 7.26153 25.0858M7.2615 25.0858L7.26153 25.0858M7.26153 25.0858L12.9115 19.3613L12.8403 19.2911L12.9115 19.3613C13.052 19.2189 13.0936 19.0056 13.0169 18.8204L12.9245 18.8587L13.017 18.8205C12.9404 18.6352 12.7602 18.5143 12.5603 18.5143H9.86442V7.11974C9.86442 6.84572 9.64364 6.62269 9.3698 6.62269H7.38296L11.9603 1.96851L16.5859 6.62269H14.6376C14.3637 6.62269 14.143 6.84572 14.143 7.11974V22.9918C14.143 23.2658 14.3637 23.4888 14.6376 23.4888C14.9114 23.4888 15.1322 23.2658 15.1322 22.9918V7.61679H17.7791C17.9796 7.61679 18.1597 7.49538 18.2362 7.3097M18.2362 7.3097L18.1438 7.27161M18.2362 7.3097C18.2362 7.30971 18.2362 7.30973 18.2362 7.30974L18.1438 7.27161M18.1438 7.27161C18.2049 7.12331 18.171 6.95238 18.0583 6.83883L15.1322 7.51679H17.7791C17.9388 7.51679 18.0826 7.42011 18.1438 7.27161ZM8.87499 7.61679V7.51679H8.97499V7.61679H8.87499ZM11.4715 19.4084H11.612L11.5133 19.5084H11.3728L11.4715 19.4084ZM6.97941 24.1022L6.90916 24.1734L6.83867 24.1025L6.90891 24.0313L6.97941 24.1022ZM2.27273 19.5084L2.17334 19.4084H2.31433L2.41372 19.5084H2.27273ZM3.92387 18.5143V18.6143H3.82387V18.5143H3.92387Z"
            fill="#771B85"
            stroke="#771B85"
            strokeWidth="0.2"
          />
        </g>
        <defs>
          <clipPath id="clip0_3528_3368">
            <rect
              width="19"
              height="26"
              fill="white"
              transform="matrix(-1 0 0 1 19 0)"
            />
          </clipPath>
        </defs>
      </svg>
    );
  } else if (name === "arrow-right-purple") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="24"
        viewBox="0 0 32 24"
        fill="none"
        className={className}
      >
        <path
          d="M1 11.6973L29.8253 11.7748"
          stroke="#56345B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.7677 1L30.667 11.7773L19.7071 22.4958"
          stroke="#56345B"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "arrow-right-blue") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="24"
        viewBox="0 0 32 24"
        fill="none"
        className={className}
      >
        <path
          d="M1 11.9346L29.8253 12.0122"
          stroke="#3756DF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.7676 1.2373L30.6669 12.0146L19.707 22.7331"
          stroke="#3756DF"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "external-link-dark") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        className={className}
      >
        <path
          d="M16 3H21V8"
          stroke="#1D2639"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 10L21 3"
          stroke="#1D2639"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 14V19C19 20.105 18.105 21 17 21H5C3.895 21 3 20.105 3 19V7C3 5.895 3.895 5 5 5H10"
          stroke="#1D2639"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "arrow-down-white-purple") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <circle cx="16" cy="16" r="16" fill="white" />
        <path
          d="M16.0166 24.0552C15.871 24.0552 15.7254 24.0016 15.6104 23.8866L10.1999 18.4761C9.97764 18.2539 9.97764 17.886 10.1999 17.6638C10.4221 17.4415 10.79 17.4415 11.0122 17.6638L16.0166 22.6681L21.0209 17.6638C21.2432 17.4415 21.611 17.4415 21.8332 17.6638C22.0555 17.886 22.0555 18.2539 21.8332 18.4761L16.4227 23.8866C16.3078 24.0016 16.1622 24.0552 16.0166 24.0552Z"
          fill="#771B85"
        />
        <path
          d="M16.0162 24.0554C15.702 24.0554 15.4414 23.7948 15.4414 23.4806V9.6861C15.4414 9.37189 15.702 9.11133 16.0162 9.11133C16.3304 9.11133 16.5909 9.37189 16.5909 9.6861V23.4806C16.5909 23.7948 16.3304 24.0554 16.0162 24.0554Z"
          fill="#771B85"
        />
      </svg>
    );
  } else if (name === "dropdown-caret") {
    return (
      <svg
        width="18"
        height="10"
        viewBox="0 0 18 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M1 1L9 9L17 1"
          stroke="#3756DF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "info") {
    return (
      <svg
        width={renderedSize}
        height={renderedSize}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M6.26513 4.5C6.19613 4.5 6.14013 4.556 6.14063 4.625C6.14063 4.694 6.19663 4.75 6.26563 4.75C6.33463 4.75 6.39063 4.694 6.39063 4.625C6.39063 4.556 6.33463 4.5 6.26513 4.5"
          stroke="#3756DF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.26562 11V11C3.78012 11 1.76562 8.9855 1.76562 6.5V6.5C1.76562 4.0145 3.78012 2 6.26562 2V2C8.75113 2 10.7656 4.0145 10.7656 6.5V6.5C10.7656 8.9855 8.75113 11 6.26562 11Z"
          stroke="#3756DF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.26562 6.5V9"
          stroke="#3756DF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "info-black") {
    return (
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M5.9995 4.5C5.9305 4.5 5.8745 4.556 5.875 4.625C5.875 4.694 5.931 4.75 6 4.75C6.069 4.75 6.125 4.694 6.125 4.625C6.125 4.556 6.069 4.5 5.9995 4.5"
          stroke="#323232"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 11V11C3.5145 11 1.5 8.9855 1.5 6.5V6.5C1.5 4.0145 3.5145 2 6 2V2C8.4855 2 10.5 4.0145 10.5 6.5V6.5C10.5 8.9855 8.4855 11 6 11Z"
          stroke="#323232"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6.5V9"
          stroke="#323232"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "sorting-caret") {
    return (
      <svg
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.71877 0.665003C8.81774 0.774913 8.86719 0.905164 8.86719 1.0556C8.86719 1.20607 8.81774 1.33632 8.71877 1.44623L5.21878 5.33509C5.11981 5.445 5.00266 5.5 4.86724 5.5C4.73182 5.5 4.61459 5.445 4.5157 5.33509L1.01572 1.44623C0.916743 1.33635 0.867187 1.2061 0.867187 1.0556C0.867187 0.905164 0.916743 0.774913 1.01572 0.665002C1.11461 0.555031 1.23183 0.5 1.36726 0.5L8.36723 0.5C8.50265 0.5 8.61977 0.555031 8.71877 0.665003Z"
          fill="#1D2639"
        />
      </svg>
    );
  } else if (name === "sorting-caret-up") {
    return (
      <svg
        width="9"
        height="6"
        viewBox="0 0 9 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.71877 0.665003C8.81774 0.774913 8.86719 0.905164 8.86719 1.0556C8.86719 1.20607 8.81774 1.33632 8.71877 1.44623L5.21878 5.33509C5.11981 5.445 5.00266 5.5 4.86724 5.5C4.73182 5.5 4.61459 5.445 4.5157 5.33509L1.01572 1.44623C0.916743 1.33635 0.867187 1.2061 0.867187 1.0556C0.867187 0.905164 0.916743 0.774913 1.01572 0.665002C1.11461 0.555031 1.23183 0.5 1.36726 0.5L8.36723 0.5C8.50265 0.5 8.61977 0.555031 8.71877 0.665003Z"
          fill="#1D2639"
          transform="rotate(180 4.5 3)"
        />
      </svg>
    );
  } else if (name === "tuple-remove") {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M9.88661 6.11328L6.11328 9.88661"
          stroke="#3756DF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.88661 9.88661L6.11328 6.11328"
          stroke="#3756DF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
          stroke="#3756DF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "tuple-remove-purple") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M9.88661 6.11328L6.11328 9.88661"
          stroke="#771B85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.88661 9.88661L6.11328 6.11328"
          stroke="#771B85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
          stroke="#771B85"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  } else if (name === "check") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 6.5L9 17.5L4 12.5"
          stroke="#771B85"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  } else if (name === "check-blue") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20 6.5L9 17.5L4 12.5"
          stroke="#3756DF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  } else if (name === "edit") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g id="Group">
          <g id="Group_2">
            <path
              id="Path"
              d="M17.5389 10.1209L13.8789 6.46094"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.251 21.0003H3V17.7493C3 17.4843 3.105 17.2293 3.293 17.0423L16.627 3.70731C17.018 3.31631 17.651 3.31631 18.041 3.70731L20.292 5.95831C20.683 6.34931 20.683 6.98231 20.292 7.37231L6.958 20.7073C6.771 20.8953 6.516 21.0003 6.251 21.0003V21.0003Z"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === "delete") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g id="Group">
          <g id="Group_2">
            <path
              id="Path"
              d="M18 6V18.75C18 19.993 16.973 21 15.731 21H8.231C6.988 21 6 19.993 6 18.75V6"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_2"
              d="M19.5 6H4.5"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_3"
              d="M10 3H14"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_4"
              d="M14 10V17"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_5"
              d="M10 17V10"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === "share") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g id="Group">
          <g id="Group_2">
            <path
              id="Path"
              d="M16 3H21V8"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_2"
              d="M14 10L21 3"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Path_3"
              d="M19 14V19C19 20.105 18.105 21 17 21H5C3.895 21 3 20.105 3 19V7C3 5.895 3.895 5 5 5H10"
              stroke="#3756DF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === "profile") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
      >
        <g id="Icon/User">
          <g id="Group">
            <path
              id="Shape"
              d="M16.0001 14.2501C13.9472 14.2501 12.2671 12.5707 12.2671 10.517H10.7671C10.7671 13.3994 13.119 15.7501 16.0001 15.7501V14.2501ZM12.2671 10.517C12.2671 8.45596 13.9546 6.75 16.0001 6.75V5.25C13.1117 5.25 10.7671 7.64208 10.7671 10.517H12.2671ZM16.0001 6.75C18.0531 6.75 19.7332 8.42934 19.7332 10.483H21.2332C21.2332 7.6007 18.8813 5.25 16.0001 5.25V6.75ZM19.7332 10.483C19.7332 12.5441 18.0457 14.2501 16.0001 14.2501V15.7501C18.8886 15.7501 21.2332 13.358 21.2332 10.483H19.7332ZM23.0002 23.2501H9.00001V24.7501H23.0002V23.2501ZM9.00001 23.2501C8.86422 23.2501 8.75 23.1358 8.75 23.0001H7.25C7.25 23.9643 8.03579 24.7501 9.00001 24.7501V23.2501ZM8.75 23.0001V22H7.25V23.0001H8.75ZM8.75 22C8.75 20.2142 10.2142 18.75 12 18.75V17.25C9.3858 17.25 7.25 19.3858 7.25 22H8.75ZM12 18.75H20.0001V17.25H12V18.75ZM20.0001 18.75C21.7859 18.75 23.2502 20.2142 23.2502 22H24.7502C24.7502 19.3858 22.6144 17.25 20.0001 17.25V18.75ZM23.2502 22V23.0001H24.7502V22H23.2502ZM23.2502 23.0001C23.2502 23.1358 23.1359 23.2501 23.0002 23.2501V24.7501C23.9644 24.7501 24.7502 23.9643 24.7502 23.0001H23.2502Z"
              fill="#1D2639"
            />
          </g>
        </g>
      </svg>
    );
  } else if (name === "plus") {
    return (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 5V19M5 12H19"
          stroke="#3756DF"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  } else return <span>?</span>;
}

export default Icon;
